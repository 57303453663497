import * as actionTypes from "./actionTypes";

//*  ACTIVITY DETAILED WORKING TIME TRENDS *//

export const fetchScreenshotTable = (payload) => {
  return {
    type: actionTypes.FETCH_SCREENSHOT_TABLE,
    payload,
  };
};

export const fetchScreenshotTableSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_SCREENSHOT_TABLE_SUCCESS,
    payload,
  };
};

export const fetchScreenshotTableFailed = (payload) => {
  return {
    type: actionTypes.FETCH_SCREENSHOT_TABLE_FAILED,
    payload,
  };
};

export const setHourlyActivityProductivityLoading = (payload) => {
  return {
    type: actionTypes.SET_HOURLY_ACTIVITY_PRODUCTIVITY_LOADING,
    payload,
  };
};

export const setHourlySessionBreakdown = (payload) => {
  return {
    type: actionTypes.SET_HOURLY_SESSION_BREAKDOWN,
    payload,
  };
};

export const fetchHourlyActivityProductivity = (payload) => {
  return {
    type: actionTypes.FETCH_HOURLY_ACTIVITY_PRODUCTIVITY,
    payload,
  };
};

export const fetchHourlyActivityProductivitySuccess = (payload) => {
  return {
    type: actionTypes.FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_SUCCESS,
    payload,
  };
};

export const fetchHourlyActivityProductivityFailed = (payload) => {
  return {
    type: actionTypes.FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_FAILED,
    payload,
  };
};

export const fetchPowerLogs = (payload) => {
  return {
    type: actionTypes.FETCH_POWER_LOGS,
    payload,
  };
};

export const fetchPowerLogsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_POWER_LOGS_SUCCESS,
    payload,
  };
};

export const fetchPowerLogsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_POWER_LOGS_FAILED,
    payload,
  };
};

export const fetchSessionAppLogs = (payload) => {
  return {
    type: actionTypes.FETCH_SESSION_APP_LOG,
    payload,
  };
};

export const fetchSessionAppSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_SESSION_APP_LOG_SUCCESS,
    payload,
  };
};

export const fetchSessionAppFailed = (payload) => {
  return {
    type: actionTypes.FETCH_SESSION_APP_LOG_FAILED,
    payload,
  };
};

export const resetScreenshotTable = () => {
  return {
    type: actionTypes.RESET_SCREENSHOT_TABLE,
  };
};

export const fetchScreenshotListByUserIdInitial = () => {
  return {
    type: actionTypes.FETCH_SCREENSHOT_LIST_USER_ID_INITIAL,
  };
};

export const fetchScreenshotListByUserIdProcess = (payload) => {
  return {
    type: actionTypes.FETCH_SCREENSHOT_LIST_USER_ID_PROCESS,
    payload,
  };
};

export const fetchScreenshotListByUserIdSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_SCREENSHOT_LIST_USER_ID_SUCCESS,
    payload,
  };
};

export const fetchScreenshotListByUserIdFailed = (payload) => {
  return {
    type: actionTypes.FETCH_SCREENSHOT_LIST_USER_ID_FAILED,
    payload,
  };
};

export const downloadScreenshot = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_SCREENSHOT,
    payload,
  };
};

export const downloadScreenshotSuccess = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_SCREENSHOT_SUCCESS,
    payload,
  };
};

export const downloadScreenshotFailed = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_SCREENSHOT_FAILED,
    payload,
  };
};

export const downloadScreenshotsZip = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_SCREENSHOTS_ZIP,
    payload,
  };
};

export const downloadScreenshotsZipSuccess = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_SCREENSHOTS_ZIP_SUCCESS,
    payload,
  };
};

export const downloadScreenshotsZipFailed = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_SCREENSHOTS_ZIP_FAILED,
    payload,
  };
};

export const flagScreenshot = (payload) => {
  return {
    type: actionTypes.FLAG_SCREENSHOT,
    payload,
  };
};

export const flagScreenshotSuccess = (payload) => {
  return {
    type: actionTypes.FLAG_SCREENSHOT_SUCCESS,
    payload,
  };
};

export const flagScreenshotFailed = (payload) => {
  return {
    type: actionTypes.FLAG_SCREENSHOT_FAILED,
    payload,
  };
};

export const unflagScreenshot = (payload) => {
  return {
    type: actionTypes.UNFLAG_SCREENSHOT,
    payload,
  };
};

export const unflagScreenshotSuccess = (payload) => {
  return {
    type: actionTypes.UNFLAG_SCREENSHOT_SUCCESS,
    payload,
  };
};

export const unflagScreenshotFailed = (payload) => {
  return {
    type: actionTypes.UNFLAG_SCREENSHOT_FAILED,
    payload,
  };
};

export const getFlaggedScreenshot = (payload) => {
  return {
    type: actionTypes.GET_FLAGGED_SCREENSHOT,
    payload,
  };
};

export const getFlaggedScreenshotSuccess = (payload) => {
  return {
    type: actionTypes.GET_FLAGGED_SCREENSHOT_SUCCESS,
    payload,
  };
};

export const getFlaggedScreenshotFailed = (payload) => {
  return {
    type: actionTypes.GET_FLAGGED_SCREENSHOT_FAILED,
    payload,
  };
};

export const setScreenshotErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_SCREENSHOT_ERROR_MESSAGE,
    payload,
  };
};

export const fetchScreenRecordings = (payload) => {
  return {
    type: actionTypes.FETCH_SCREEN_RECORDINGS,
    payload,
  };
};

export const fetchScreenRecordingsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_SCREEN_RECORDINGS_SUCCESS,
    payload,
  };
};

export const fetchScreenRecordingsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_SCREEN_RECORDINGS_FAILED,
    payload,
  };
};
