import React from "react";
import axios from "axios";
import meta from "./meta.json";
import { permissionsMap } from "constants/constant";
import { notification } from "antd";
import {
  SOMETHING_WENT_WRONG,
  DELETE_PROJECT_ERROR_MESSAGE,
  DELETE_TASK_ERROR_MESSAGE,
  DELETE_APPLICATION_POLICY_ERROR_MESSAGE,
} from "constants/staticText";

const Client = axios.create({
  baseURL: process.env.REACT_APP_CONTROL_ENDPOINT,
  timeout: 300000,
  headers: { ...JSON.parse(localStorage.getItem("headers") || "{}") },
});

const RawHttpClient = axios.create({
  baseURL: process.env.REACT_APP_CONTROL_ENDPOINT,
  timeout: 1200000,
  headers: { "Content-Type": "application/octet-stream" },
  responseType: "blob",
});

const ContcrolClient = axios.create({
  baseURL: process.env.REACT_APP_CONTROL_CENTER_ENDPOINT,
  timeout: 120000,
});

const AuthClient = axios.create({
  baseURL: process.env.REACT_APP_KEYCLOAK_BASE_URL,
  timeout: 120000,
});

//interceptor to parse the request and attach endpoint
Client.interceptors.request.use(
  function (config) {
    // console.log("Request interceptors", config)
    // Do something before request is sent
    // config.headers.Authorization = `Bearer ${getToken()}`
    // config.method = `POST`;
    config.url = getEndpoint(config.url);
    // config.url= "v2/UserProfileApi"

    // console.log("Modified Request interceptors", config);
    return config;
  },
  function (error) {
    // Do something with request error
    console.error("Error in axios client", error);
    return Promise.reject(error);
  }
);

ContcrolClient.interceptors.request.use(
  function (config) {
    // console.log("Request interceptors", config)
    // Do something before request is sent
    // config.headers.Authorization = `Bearer ${getToken()}`
    // config.method = `POST`;
    config.url = getEndpoint(config.url);
    // config.url= "v2/UserProfileApi"

    // console.log("Modified Request interceptors", config);
    return config;
  },
  function (error) {
    // Do something with request error
    console.error("Error in axios client", error);
    return Promise.reject(error);
  }
);

Client.interceptors.response.use(
  function (response) {
    // If the request succeeds, we don't have to do anything and just return the response
    // console.log("Axios response in interceptor", response);
    return response;
  },
  function (err) {
    // const errorResponse = error.response
    // if (isTokenExpiredError(errorResponse)) {
    // return resetTokenAndReattemptRequest(error)
    // }
    // If the error is due to other reasons, we just throw it back to axios
    console.error(
      `We360: API FAILED -> ${err?.response?.config?.method
        ?.toString()
        .toUpperCase()}: ${err?.response?.config?.url.toString()} ${
        err?.response?.status
      } ${err?.response?.data?.errors?.[0]?.id}`
    );

    if (
      err.response?.status < 500 &&
      err.response?.status !== 403 &&
      err.response?.status !== 401
    ) {
      if (
        err?.response?.config?.url.toString().search("projects") > 0 &&
        err?.response?.config?.method === "delete"
      ) {
        notification.error({
          message: DELETE_PROJECT_ERROR_MESSAGE,
        });
      } else if (
        err?.response?.config?.url.toString().search("tasks") > 0 &&
        err?.response?.config?.method === "delete"
      ) {
        notification.error({
          message: DELETE_TASK_ERROR_MESSAGE,
        });
      } else if (
        err?.response?.config?.url.toString().search("application_policies") >
          0 &&
        err?.response?.config?.method === "delete"
      ) {
        notification.error({
          message: DELETE_APPLICATION_POLICY_ERROR_MESSAGE,
        });
      } else {
        notification.error({
          message:
            err?.response?.data?.errors?.[0]?.detail || SOMETHING_WENT_WRONG,
        });
      }
    } else if (
      err?.response?.config?.url?.toString().search("onboarding") > 0
    ) {
      notification.error({
        message: (
          <div>
            Something went wrong, please contact support{" "}
            <a href={raiseTicketURL} target="_blank" rel="noreferrer">
              here
            </a>
          </div>
        ),
      });
    }

    return Promise.reject(err);
  }
);

//interceptor to detect if error occurred and check if need to log
ContcrolClient.interceptors.response.use(
  function (response) {
    // If the request succeeds, we don't have to do anything and just return the response
    // console.log("Axios response in interceptor", response);
    return response;
  },
  function (err) {
    console.error("Axios response error in interceptor", err);
    // const errorResponse = error.response
    // if (isTokenExpiredError(errorResponse)) {
    // return resetTokenAndReattemptRequest(error)
    // }
    // If the error is due to other reasons, we just throw it back to axios

    console.error(
      `We360: API FAILED -> ${err?.response?.config?.method
        ?.toString()
        .toUpperCase()}: ${err?.response?.config?.url?.toString()} ${
        err?.response?.status
      } ${err?.response?.data?.errors?.[0]?.id}`
    );

    if (
      err?.response?.status < 500 &&
      err.response?.status !== 403 &&
      err.response?.status !== 401
    ) {
      notification.error({
        message:
          err?.response?.data?.errors?.[0]?.detail || SOMETHING_WENT_WRONG,
      });
    } else {
      notification.error({
        message: (
          <div>
            Something went wrong, please contact support{" "}
            <a href={raiseTicketURL} target="_blank" rel="noreferrer">
              here
            </a>
          </div>
        ),
      });
    }

    return Promise.reject(err);
  }
);

function getEndpoint(endpoint_constant) {
  const endpoint = meta[endpoint_constant];
  if (!endpoint) {
    return endpoint_constant;
  }
  return endpoint;
}

const getGoogleURL = (authKeycloakUrl, keycloakRealm, origin) =>
  `${authKeycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/auth?client_id=signup&redirect_uri=${origin}%2Fcomplete-google-setup&response_type=code&scope=openid&kc_idp_hint=google`;

export const getWebsocketPullEndpoint = (coreApiUrl) =>
  `${coreApiUrl}/websockets/subscribe/me_public`.replace("http", "ws");

export const getAvatarUrl = (coreApiUrl, identityID) =>
  `${coreApiUrl}/api/v1/me/avatars/${identityID}`;

export const getLogoUrl = (coreApiUrl) => `${coreApiUrl}/api/v1/me/tenant_logo`;

export const getDownloadAppUrl = (coreApiUrl, appUrl, fileName) =>
  `${coreApiUrl}/public/download-proxy/?url=${appUrl}&fileName=${fileName}`;

export const getScreenshotUrl = (coreApiUrl, identityID, imagePath) =>
  `${coreApiUrl}/api/v1/analytics/screenshots/${identityID}/single/?imageId=${imagePath}`;

export const getAttachmentUrl = (coreApiUrl, taskID, attachmentId) =>
  `${coreApiUrl}/api/v1/tasks/${taskID}/attachments/${attachmentId}`;

export const getScreenshotUrlThumbnail = (coreApiUrl, identityID, imagePath) =>
  `${coreApiUrl}/api/v1/analytics/screenshots/${identityID}/thumbs/?imageId=${imagePath}`;

export const getScreenRecordingUrl = (
  coreApiUrl,
  identityID,
  sessionId,
  date,
  attachment = ""
) =>
  `${coreApiUrl}/api/v1/screen-recording/${identityID}/single/?SessionId=${sessionId}&date=${date}${
    attachment ? `&attachment=${attachment}` : ""
  }`;
export const getIconUrl = (appName = "default") =>
  "https://we360indpublic.blob.core.windows.net/assets/app_icons/" +
  appName?.toString()?.split(" ")?.join("_")?.toLowerCase() +
  ".svg";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const extractPermissions = (token) => {
  const permissions = [permissionsMap.TENANT_USER];
  try {
    const { resource_access, azp } = JSON.parse(atob(token.split(".")[1]));
    if (
      resource_access?.[azp]?.roles &&
      resource_access[azp].roles.includes(permissionsMap.TENANT_OWNER)
    ) {
      permissions.push(permissionsMap.TENANT_OWNER);
    }

    if (
      resource_access?.[azp]?.roles &&
      resource_access[azp].roles.includes(permissionsMap.IAM_MANAGE_ALL)
    ) {
      permissions.push(permissionsMap.IAM_MANAGE_ALL);
    }
  } catch (e) {
    console.error(e);
  }
  return permissions;
};

const getCountryFromIP = async () => {
  try {
    const { data } = await axios.get("https://ipapi.co/json/");
    return data.country_name;
  } catch (error) {
    console.log(error);
  }
  return "";
};

const getInfoFromIP = async (ip) => {
  try {
    const { data } = await axios.get(`https://ipapi.co/${ip}/json/`);
    return data;
  } catch (error) {
    console.log(error);
  }

  return { ip };
};

export const requestOnboardingURL =
  "https://calendly.com/we360ai_support/support-and-onboarding?utm_source=Product&utm_medium=WebApplication&utm_term=RequestOnboarding&utm_campaign=HelpCenter";

export const raiseTicketURL = "https://we360.zohodesk.in/portal/en/newticket";

export const newFeatureUpdate =
  "https://feedback.we360.ai/b/zv9xr6v1/feature-ideas";

export {
  Client,
  RawHttpClient,
  ContcrolClient,
  AuthClient,
  getGoogleURL,
  parseJwt,
  extractPermissions,
  getCountryFromIP,
  getInfoFromIP,
};
