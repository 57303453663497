import * as actionTypes from "./actionTypes";

const initialState = {
  tableLoading: true,
  tableData: [],
  pagination: { total_records: 0 },
  screenshotErrorMessage: "",
  hourlySessionBreakdown: null,
  hourlyActivityProductivity: null,
  hourlyActivityProductivityLoading: true,
  screenRecordingData: {},
  screenRecordingSuccess: false,
  screenRecordingLoading: false,
  powerLogs: [],
  powerLogsLoading: true,
  sessionAppLogs: [],
  sessionAppLogsLoading: true,
  screenshotListByUserProcessing: false,
  screenshotListByUserSuccess: false,
  screenshotListByUserfailed: false,
  screenshotListByUserData: [],
  screenshotListByUserMessage: "",
  screenshotPagination: "",
  downloadScreenshotLoading: "",
  downloadScreenshotImagePath: null,
  downloadScreenshotsZipLoading: "",
  flagScreenshotLoading: false,
  flagScreenshotImagePath: null,
  flaggedScreenshotData: {},
  getFlaggedScreenshotLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.RESET_SCREENSHOT_TABLE:
      return {
        ...state,
        tableData: [],
      };

    case actionTypes.FETCH_SCREENSHOT_TABLE:
      return {
        ...state,
        tableLoading: true,
        screenshotErrorMessage: "",
      };

    case actionTypes.FETCH_SCREENSHOT_TABLE_SUCCESS:
      return {
        ...state,
        tableLoading: false,
        tableData: [...state.tableData, ...action.payload?.data],
        pagination: action.payload.optional?.pagination || state.pagination,
      };

    case actionTypes.FETCH_SCREENSHOT_TABLE_FAILED:
      return {
        ...state,
        tableLoading: false,
        screenshotErrorMessage: action.payload,
      };

    case actionTypes.SET_HOURLY_ACTIVITY_PRODUCTIVITY_LOADING:
      return {
        ...state,
        hourlyActivityProductivityLoading: action.payload,
      };

    case actionTypes.SET_HOURLY_SESSION_BREAKDOWN:
      return {
        ...state,
        hourlySessionBreakdown: action.payload,
        hourlyActivityProductivityLoading: true,
        hourlyActivityProductivity: null,
        powerLogsLoading: true,
        powerLogs: [],
        screenshotErrorMessage: "",
      };

    case actionTypes.FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_SUCCESS:
      return {
        ...state,
        hourlyActivityProductivity: action.payload,
        hourlyActivityProductivityLoading: false,
        screenshotErrorMessage: "",
      };

    case actionTypes.FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_FAILED:
      return {
        ...state,
        hourlyActivityProductivity: null,
        hourlyActivityProductivityLoading: false,
        screenshotErrorMessage: action.payload,
      };

    case actionTypes.FETCH_POWER_LOGS_SUCCESS:
      return {
        ...state,
        powerLogs: action.payload,
        powerLogsLoading: false,
        screenshotErrorMessage: "",
      };

    case actionTypes.FETCH_POWER_LOGS_FAILED:
      return {
        ...state,
        powerLogs: null,
        powerLogsLoading: false,
        screenshotErrorMessage: action.payload,
      };

    case actionTypes.FETCH_SCREEN_RECORDINGS:
      return {
        ...state,
        screenRecordingLoading: true,
        screenRecordingSuccess: false,
        screenshotErrorMessage: "",
      };

    case actionTypes.FETCH_SCREEN_RECORDINGS_SUCCESS:
      return {
        ...state,
        screenRecordingData: action.payload,
        screenRecordingLoading: false,
        screenRecordingSuccess: true,
        screenshotErrorMessage: "",
      };

    case actionTypes.FETCH_SCREEN_RECORDINGS_FAILED:
      return {
        ...state,
        screenRecordingData: {},
        screenRecordingLoading: false,
        screenRecordingSuccess: false,
        screenshotErrorMessage: action.payload,
      };

    case actionTypes.FETCH_SESSION_APP_LOG_SUCCESS:
      return {
        ...state,
        sessionAppLogs:
          (action.payload &&
            action.payload.filter(function (app) {
              return app.event_type === "APP_LOG";
            })) ||
          [],
        sessionAppLogsLoading: false,
        screenshotErrorMessage: "",
      };

    case actionTypes.FETCH_SESSION_APP_LOG_FAILED:
      return {
        ...state,
        sessionAppLogs: null,
        sessionAppLogsLoading: false,
        screenshotErrorMessage: action.payload,
      };

    case actionTypes.FETCH_SCREENSHOT_LIST_USER_ID_INITIAL:
      return {
        ...state,
        screenshotListByUserProcessing: false,
        screenshotListByUserSuccess: false,
        screenshotListByUserfailed: false,
        screenshotListByUserData: [],
        screenshotPagination: "",
        screenshotListByUserMessage: "",
      };

    case actionTypes.FETCH_SCREENSHOT_LIST_USER_ID_PROCESS:
      return {
        ...state,
        screenshotListByUserProcessing: true,
        screenshotListByUserSuccess: false,
        screenshotListByUserfailed: false,
        screenshotPagination: "",
        screenshotListByUserMessage: "",
      };

    case actionTypes.FETCH_SCREENSHOT_LIST_USER_ID_SUCCESS:
      return {
        ...state,
        screenshotListByUserProcessing: false,
        screenshotListByUserSuccess: true,
        screenshotListByUserfailed: false,
        screenshotListByUserData: [...action.payload?.data],
        screenshotListByUserMessage: "",
        screenshotPagination: action.payload?.optional?.pagination,
      };

    case actionTypes.FETCH_SCREENSHOT_LIST_USER_ID_FAILED:
      return {
        ...state,
        screenshotListByUserProcessing: false,
        screenshotListByUserSuccess: false,
        screenshotListByUserfailed: true,
        screenshotPagination: "",
        screenshotListByUserMessage: action.payload?.replaceAll("_", " "),
      };

    case actionTypes.DOWNLOAD_SCREENSHOT:
      return {
        ...state,
        downloadScreenshotLoading: true,
        downloadScreenshotImagePath: action.payload.imagePath,
      };

    case actionTypes.DOWNLOAD_SCREENSHOT_SUCCESS:
      return {
        ...state,
        downloadScreenshotLoading: false,
        downloadScreenshotImagePath: null,
      };

    case actionTypes.DOWNLOAD_SCREENSHOT_FAILED:
      return {
        ...state,
        downloadScreenshotLoading: false,
        downloadScreenshotImagePath: null,
        screenshotErrorMessage: action.payload,
      };

    case actionTypes.DOWNLOAD_SCREENSHOTS_ZIP:
      return {
        ...state,
        downloadScreenshotsZipLoading: true,
      };

    case actionTypes.DOWNLOAD_SCREENSHOTS_ZIP_SUCCESS:
      return {
        ...state,
        downloadScreenshotsZipLoading: false,
      };

    case actionTypes.DOWNLOAD_SCREENSHOTS_ZIP_FAILED:
      return {
        ...state,
        downloadScreenshotsZipLoading: false,
        screenshotErrorMessage: action.payload,
      };

    case actionTypes.FLAG_SCREENSHOT:
      return {
        ...state,
        flagScreenshotLoading: true,
        flagScreenshotImagePath: action.payload.body.screenshotId,
      };

    case actionTypes.FLAG_SCREENSHOT_SUCCESS:
      return {
        ...state,
        flagScreenshotLoading: false,
        flagScreenshotImagePath: null,
      };

    case actionTypes.FLAG_SCREENSHOT_FAILED:
      return {
        ...state,
        flagScreenshotLoading: false,
        screenshotErrorMessage: action.payload,
        flagScreenshotImagePath: null,
      };

    case actionTypes.UNFLAG_SCREENSHOT:
      return {
        ...state,
        flagScreenshotLoading: true,
        flagScreenshotImagePath: action.payload.imagePath,
      };

    case actionTypes.UNFLAG_SCREENSHOT_SUCCESS:
      return {
        ...state,
        flagScreenshotLoading: false,
        flagScreenshotImagePath: null,
      };

    case actionTypes.UNFLAG_SCREENSHOT_FAILED:
      return {
        ...state,
        flagScreenshotLoading: false,
        screenshotErrorMessage: action.payload,
        flagScreenshotImagePath: null,
      };

    case actionTypes.GET_FLAGGED_SCREENSHOT:
      return {
        ...state,
        getFlaggedScreenshotLoading: true,
      };

    case actionTypes.GET_FLAGGED_SCREENSHOT_SUCCESS:
      return {
        ...state,
        getFlaggedScreenshotLoading: false,
        flaggedScreenshotData: action.payload.items.reduce(
          (acc, flagItem) => ({ ...acc, [flagItem.screenshot_id]: flagItem }),
          {}
        ),
      };

    case actionTypes.GET_FLAGGED_SCREENSHOT_FAILED:
      return {
        ...state,
        getFlaggedScreenshotLoading: false,
        screenshotErrorMessage: action.payload,
      };

    case actionTypes.SET_SCREENSHOT_ERROR_MESSAGE:
      return {
        ...state,
        screenshotErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
